import React, { useState, useEffect } from 'react';
import { Logo } from './Logo';
import GroupBar from './GroupBar';
import { HeaderNavigation } from './HeaderNavigation';
import { SearchForm } from './SearchForm';
import './Header.scss';

export const Header = (location) => {
  const [navOpen, setNavOpen] = useState(false);

  const closeNav = () => {
    setNavOpen(false);
    document.body.classList.remove('nav-open');
  }

  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      closeNav();
    }
  }

  const handleHamburgerPress = () => {
    setNavOpen(!navOpen);
    if (document.body.classList.contains('nav-open')) {
      document.body.classList.remove('nav-open');
    } else {
      document.body.classList.add('nav-open');
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    // Close nav on page change
    const { state: locationState } = location.location;
    if (locationState && locationState.prevPath !== location.location.pathname) {
      closeNav();
    }
  }, []);

  return (
    <header>
      <GroupBar />
      <div className="wide-wrapper">
        <div className="wrapper">
          <Logo />
          <HeaderNavigation
            isOpen={navOpen}
            location={location}
            closeNav={closeNav}
          />
          <SearchForm />
          {/* eslint-disable-next-line */}
          <span
            className="hamburger"
            onClick={handleHamburgerPress}
          />
        </div>
      </div>
    </header>
  );
}

import React, { useState, useEffect } from 'react';
import { Index } from 'elasticlunr';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './SearchForm.scss';

export const SearchForm = () => {
  const [searchFormActive, setSearchFormActive] = useState(false);
  return (
    <div className="search-form" onSubmit={(e) => e.preventDefault}>
      <div className={`search-links-container${searchFormActive ? ' active' : ''}`}>
        <div className={`search-links`}>
          <Link to="/search/" className="button">Site Search</Link>
          <Link to="/products/search/" className="button">Product Search</Link>
        </div>
        <button className="search-toggle" onClick={() => setSearchFormActive(!searchFormActive)}></button>
      </div>
    </div>
  );
}

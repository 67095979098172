import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import { Logo } from './Logo';
import './Footer.scss';

const query = graphql`
  query footerQuery {
    footerNav: wordpressWpApiMenusMenusItems(slug: { eq: "header-navigation" }) {
      items {
        url
        title
        classes
      }
    }
    footerLegalNav: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
      items {
        url
        title
        classes
      }
    }
    wordpressAcfOptions {
      options {
        locations {
          locationName
          addressStreet
          addressCity
          addressRegion
          addressPostCode
          phone
          faxNumber
          email
        }
        socialLinks {
          type
          url
        }
      }
    }
  }
`;

export const Footer = ({ location }) => {
  const {
    footerNav,
    footerLegalNav,
    wordpressAcfOptions
  } = useStaticQuery(query);
  const { options = {} } = wordpressAcfOptions;
  const { locations = null, socialLinks = [] } = options;
  const date = new Date();
  const currentYear = date.getFullYear();
  const mainEmail = locations && locations.length > 0 ? locations[0].email : null;
  const isHome = location.pathname === '/';
  const BirdBrainLink = () => (
    isHome ? (
      <a
        href="https://distl.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Distl
      </a>
    ) : <span>Distl</span>
  );
  return (
    <footer>
      <div className="wide-wrapper">
        <div className="wrapper">
          <div className="footer-top">
            <Logo color="#ffffff" />
            <div className="flex-wrap">
              {footerNav && (
                <nav className="footer-navigation">
                  <ul className="footer-navigation-items">
                    {footerNav.items.map((item, index) => {
                      return (
                        <li key={index} className="footer-navigation-item">
                          <Link to={item.url} className={item.classes}>
                            {decodeEntities(item.title)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              )}
              {locations && locations.map((location, index) => {
                const {
                  locationName,
                  addressStreet,
                  addressCity,
                  addressRegion,
                  addressPostCode,
                  phone,
                } = location;
                return (
                  <div key={index} className="contact-col">
                    <span className="contact-col-heading">{decodeEntities(locationName)}</span>
                    <div className="contact-col-content">
                      {(addressStreet || addressCity || addressRegion || addressPostCode) && (
                        <p>
                          {decodeEntities(addressStreet)}
                          <br />
                          {decodeEntities(addressCity)}
                          <br />
                          {decodeEntities(addressRegion)}
                          &nbsp;
                          {decodeEntities(addressPostCode)}
                        </p>
                      )}
                    </div>
                    <a href={`tel:${phone}`} className="contact-col-phone">{phone}</a>
                  </div>
                );
              })}
              {locations && mainEmail && (
                <div className="contact-col">
                  <div className="contact-methods-row">
                    <span className="contact-col-heading">Email</span>
                    <a href={`mailto:${mainEmail}`} className="contact-col-email">
                      {mainEmail}
                    </a>
                  </div>
                  {(socialLinks && socialLinks.length > 0) && (
                    <div className="contact-methods-row">
                      <span className="contact-col-heading">Follow Us</span>
                      {socialLinks.map((link, index) => {
                        return (
                          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="contact-col-email social-link">
                            {link.type}
                          </a>
                        )
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="footer-bottom">
            {/* eslint-disable-next-line */}
            <span className="credit">
              Website by <BirdBrainLink />
            </span>
            <div className="legalities">
              {footerLegalNav && (
                <nav className="legalities-nav">
                  <ul className="legalities-nav-items">
                    {footerLegalNav.items.map((item, index) => {
                      return (
                        <li key={index} className="legalities-nav-item">
                          <Link to={item.url} className={item.classes}>
                            {decodeEntities(item.title)}
                          </Link>
                        </li>
                      );
                    })}
                    <span className="copyright">&copy;{currentYear} PAC</span> {/* eslint-disable-line */}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Logo } from './Logo';
import { decodeEntities } from '../utils/htmlParse';
import './HeaderNavigation.scss';

export const HeaderNavigation = ({ location, isOpen, closeNav }) => {
  const { headerNav } = useStaticQuery(graphql`
    query headerNavQuery {
      headerNav: wordpressWpApiMenusMenusItems(slug: { eq: "header-navigation" }) {
        items {
          url
          title
          classes
          children: wordpress_children {
            url
            title
            classes
          }
        }
      }
    }
  `);
  if (!headerNav) return <nav className="header-navigation" />;
  return (
    <nav className={`header-navigation ${isOpen ? 'open' : ''}`}>
      <div className="header-navigation-mobile-top">
        <Logo />
        <button type="button" className="menu-close-button" onClick={closeNav} />
      </div>
      <ul className="header-navigation-items">
        {headerNav.items.map((item, index) => {
          return (
            <li key={index} className="header-navigation-item">
              <Link
                to={item.url}
                className={item.classes}
                state={{ prevPath: location && location.location && location.location.pathname }}
              >
                {decodeEntities(item.title)}
              </Link>
              {item.children && (
                <ul className="header-navigation-item-children">
                  {item.children.map((child, index) => {
                    return (
                      <li key={index} className="header-navigation-item-child">
                        <Link
                          to={child.url}
                          className={child.classes}
                          state={{ prevPath: location && location.location && location.location.pathname }}
                        >
                          {decodeEntities(child.title)}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

import React from 'react'
import hifraserGroupLogo from '../images/logos/hifraser-logo.svg'
import hifraserLogo from '../images/logos/HiFraserBlue.svg'
import aquipLogo from '../images/logos/aquip-logo.svg'
import aquipFlowServicesLogo from '../images/logos/aquipflowservices-logo.svg'
import eaLogo from '../images/logos/ea-logo.svg'
import pacLogo from '../images/logos/pac-logo.svg'
import './GroupBar.scss'

const GroupBar = () => (
  <div className="header-group-bar">
    <div className="wide-wrapper">
      <div className="wrapper">
        <div className="group-parent">
          <a
            href="https://www.hifraser.com.au/about-us/our-companies/"
            target="_blank"
            rel="noopener noreferrer"
            className="group-logo"
          >
            <img
              src={hifraserGroupLogo}
              className="hifraser"
              alt="Parent company logo"
            />
          </a>
        </div>
        <a
          href="https://www.aquip.com.au"
          target="_blank"
          rel="noopener noreferrer"
          className="group-logo"
        >
          <img src={aquipLogo} className="aquip" alt="Child company logo" />
        </a>
        <a
          href="https://www.aquip.com.au"
          target="_blank"
          rel="noopener noreferrer"
          className="group-logo"
        >
          <img
            src={aquipFlowServicesLogo}
            className="aquipflowservices"
            alt="Child company logo"
          />
        </a>
        <a
          href="https://www.easternautomation.com.au"
          target="_blank"
          rel="noopener noreferrer"
          className="group-logo"
        >
          <img src={eaLogo} className="ea" alt="Child company logo" />
        </a>
        <a
          href="https://www.hifraser.com.au/"
          target="_blank"
          rel="noopener noreferrer"
          className="group-logo"
        >
          <img src={hifraserLogo} className="hif" alt="Child company logo" />
        </a>
        <div className="group-logo">
          <img src={pacLogo} className="pac" alt="Child company logo" />
        </div>
        <a
          href="https://tidalfluidpower.com.au/"
          target="_blank"
          rel="noopener noreferrer"
          className="group-logo"
        >
          <img
            src="/logos/tfp_logo.svg"
            className="tfp"
            alt="Child company logo"
          />
        </a>
      </div>
    </div>
  </div>
)

export default GroupBar
